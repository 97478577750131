import { images } from "../images/images";
const Header = () => {
    return (
    <>
    <div className="hidden xl:block lg:block md:block xl:px-96  md:px-28 sm:px-0 ">
        <header>
            <img className="h-20 p-4 xl:ml-[100px]" src={images.healium} alt="logo" /></header>
    </div>

    <div className="sm:block xl:hidden lg:hidden md:hidden">
        <header><img className="ml-5 py-2 w-[85px]" src={images.healium} alt="logo" /></header>
    </div> 

    </>
//     <header>
//     <nav className="p-4 max-w-screen-xl mx-auto">
//         {/* <img src={images.healium} className="px-52 h-12" alt="Logo"  /> */}
//         <header><img className="h-12 px-48" alt="Logo" src={images.healium}></img></header>
//     </nav>
// </header>
    
    );
}
 
export default Header;