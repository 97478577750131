import { images } from "../images/images";
import LogoCarousel from "../partials/LogoCarousel";
import React, { useState } from 'react';
import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import "slick-carousel/slick/slick.css";

import "slick-carousel/slick/slick-theme.css";
function AboutUs() {
  const [defaultImage, setDefaultImage] = useState({});
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const investors = [
    {
      id: 4,
      title: 'London',
      linkImg: images.entrepreneur2
    },

    {
      id: 3,
      title: 'Australia',
      linkImg: images.artesian
    },

    {
      id: 5,
      title: 'USA',
      linkImg: images.powerhouse
    },

    {
      id: 1,
      title: 'India',
      linkImg: images.gsf,
    },
    {
      id: 2,
      title: 'Hong Kong',
      linkImg: images.brink,
    },
    
    
    

  ];
  const handleErrorImage = (data) => {
    setDefaultImage((prev) => ({
      ...prev,
      [data.target.alt]: data.target.alt,
      linkDefault: images.gsf,
    }));
  };
  
  return (
    <>
    <div className="hidden xl:block lg:block md:block text-left w-screen md:px-28 pl-5 text-gray-500 lg:px-20 xl:px-96">
    {/* <div className="hidden xl:block lg:block md:block text-left w-screen lg:pl-5 xl:pl-5 text-gray-500 md:px-28 lg:px-20 xl:px-96"> */}
      <div className="flex justify-center">
      <img
        src={images.aboutUsImage}
        className="" alt=""
      />
      </div>
      <div className="flex-1 justify-center">
      <h1  id="about" className="py-10 text-black xl:w-10/12 lg:w-auto text-left mx-auto lg:text-[50px] xl:text-[50px] md:text-[30px]">About Us</h1>
      <p className="xl:w-10/12 lg:w-auto text-left mx-auto text-justify lg:text-[21px] md:text-[22px] xl:text-[22px] mb-5">
      Healium digital healthcare is revolutionizing renal sonography through
      the power of automated 3D ultrasound techniques, machine learning and hemodynamic
      modelling to enable more accurate and timely clinical decision. Healium automated 
      guidance system and advanced usability features help bring the benefits of renal imaging
      to more healthcare professionals in new care settings and provide greater access to patients.
      </p>
      <p className="xl:w-10/12 lg:w-auto text-left mx-auto text-justify lg:text-[21px] md:text-[22px] xl:text-[22px]  mb-5">
      We believe balance of machine learning plus nephrologists' clinical knowledge is  the future of renal care. And it's already started by Healium
      </p>
      <p className="xl:w-10/12 lg:w-auto text-left mx-auto text-justify lg:text-[21px] md:text-[22px] text-[22px] mb-5">
      Our chronic kidney disease early detection paper accepted and published by   international society of nephrology (ISN) and also, we have filed 6 patents in 
       renal sonography.
      </p>

      </div>

      <h1 id="investors" className="py-10 text-black lg:w-auto xl:w-10/12 text-left mx-auto lg:text-[50px] xl:text-[50px] md:text-[30px]">Our Investors</h1>

      
      <div className="gap-2 lg:gap-10 text-center pb-20 ml-[-30px]">
      <div className="flex gap-8 justify-between mx-auto lg:w-auto xl:w-10/12">
        
       <div className="">
        <div class="bg-white rounded-2xl shadow-lg w-36 p-1 h-20 flex items-center">
          <img src={images.entrepreneur} alt={images.entrepreneur} className="w-32 p-5"/>
        </div>
          <p className="text-center text-black mt-2"> London</p>
        </div>

        <div className="">
        <div class="bg-white rounded-2xl shadow-lg w-36 p-1 h-20 flex items-center">
          <img src={images.artesian} alt={images.artesian} className="w-36"/>
        </div>
          <p className="text-center text-black mt-2"> Australia</p>
        </div>

        <div className="">
        <div class="bg-white rounded-2xl shadow-lg w-36 p-1 h-20 flex items-center"> {/* w-48 */}
          <img src={images.powerhouse_new} alt={images.powerhouse_new} className="w-36"/>
        </div>
          <p className="text-center text-black mt-2"> USA</p>
        </div>

        <div className="">
        <div class="bg-white rounded-2xl shadow-lg w-36 p-1 h-20 flex items-center">
          <img src={images.gsf} alt={images.gsf} className="w-36"/>
        </div>
          <p className="text-center text-black mt-2"> India</p>
        </div>

        <div className="">
        <div class="bg-white rounded-2xl shadow-lg w-36 p-1 h-20 flex items-center">
          <img src={images.brink} alt={images.brink} className="w-36"/>
        </div>
          <p className="text-center text-black mt-2"> Hong Kong</p>
        </div>

        {/* <div className="">
          <img src={images.entrepreneur} alt={images.entrepreneur} className="w-36"/>
          <p className="text-center text-black mt-[-20px]"> London</p>
        </div>

        <div className="">
          <img src={images.artesian}  alt={images.artesian} className="w-36" />
          <p className="text-center text-black mt-[-20px]"> Australia</p>
        </div>

        <div className="">
          <img src={images.powerhouse_new} alt={images.powerhouse_new}  className="xl:mt-12 lg:mt-12 md:mt-5 w-48" />
         <p className="text-center text-black mt-[10px]">  USA</p>
        </div>
        
        <div className="text-left">
          <img src={images.gsf}  alt={images.gsf} className="w-36" />
          <p className="text-center text-black mt-[-20px]"> India</p>
        </div>
        
        <div className="">
          <img src={images.brink} alt={images.brink}  className="w-36" />
          <p className="text-center text-black mt-[-20px]"> Hong Kong</p>
        </div> */}
        
        </div>
      </div>
    </div>

    {/* for mobile */}
    <div className="sm:block xl:hidden lg:hidden md:hidden text-left w-screen text-gray-500">
      <div className="flex justify-center">
      <img
        src={images.aboutUsImage}
        className="" alt=""
      />
      </div>
      <div className="flex-1 justify-center p-2">
      <h1  id="about" className="py-5 ml-5 text-black xl:w-10/12 lg:w-auto text-left mx-auto text-[28px]">About Us</h1>

      <div className="flex mb-5 px-5 text-center text-justify">
          <p className="mx-auto text-[14px]">
          Healium digital healthcare is revolutionizing renal sonography through
          the power of automated 3D ultrasound techniques, machine learning and hemodynamic
          modelling to enable more accurate and timely clinical decision. Healium automated 
          guidance system and advanced usability features help bring the benefits of renal imaging
          to more healthcare professionals in new care settings and provide greater access to patients
      </p>
      </div>
      <div className="flex mb-5 px-5 text-center text-justify">
          <p className="mx-auto text-[14px]">
          We believe balance of machine learning plus nephrologists' clinical knowledge is the future of renal care. And it's already started by Healium
      </p>
      </div>
      <div className="flex mb-5 px-5 text-center text-justify">
          <p className="mx-auto text-[14px]">
          Our chronic kidney disease early detection paper accepted and published by international society of nephrology (ISN) and also, we have filed 6 patents in renal sonography.
      </p>
      </div>
      </div>

      <h1 id="investors" className="py-5 ml-5 mb-0 text-black lg:w-auto xl:w-10/12 text-left mx-auto text-[28px] p-2">Our Investors</h1>

      <div className="justify-center mb-10 p-4">
      <Slider {...settings}>
        {investors.map((item) => (
          <div className="card">
            <div className="card-top">
              <img
                src={
                  defaultImage[item.title] === item.title
                    ? defaultImage.linkDefault
                    : item.linkImg
                }
                alt={item.title}
                onError={handleErrorImage}
              />
              <h3 className="text-center mt-[-20px]">{item.title}</h3>

            </div>
          </div>
        ))}
      </Slider>
        {/* <div className="text-center">
          <img src={images.gsf}  alt={images.gsf} className=" mx-auto text-center" />
          <p className="text-center text-black mt-[-20px]"> India</p>
        </div>
        <div className="">
          <img src={images.brink} alt={images.brink}  className=" mx-auto" />
          <p className="text-center text-black mt-[-20px]"> Hong Kong</p>
        </div> */}
        {/* <div className="">
          <img src={images.artesian}  alt={images.artesian} className="w-1/4 mx-auto" />
          <p className="text-center text-black mt-[-20px]"> Australia</p>
        </div>
        <div className="">
          <img
            src={images.entrepreneur} alt={images.entrepreneur} 
            className="w-1/4 mx-auto"
          />
          <p className="text-center text-black mt-[-20px]"> London</p>
        </div>
        <div className="">
          <img src={images.powerhouse_new} alt={images.powerhouse_new}  className="w-36 mx-auto" />
         <p className="text-center text-black mb-4">  USA</p>
        </div> */}
        </div>

    </div>
    </>
  )
}

export default AboutUs

