import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const TalktoUs = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_kris14r', 'template_h3v900e',form.current, 'zBUDFsjnMwnBIMlCR')
      .then((result) => {
        toast.success("E-Mail Sent Successfully")
      }, (error) => {
        toast.error("E-Mail Not Sent")
      });
  };


  return (
    <>
     {/* <div className="w-screen xl:pl-6 lg:pl-6 md:pl-6 sm:pl-0 pt-10 pb-10 bg-gray-200 xl:px-96">
      <h1 className="w-10/12 text-left mx-auto text-left mb-10 xl:text-[50px] lg:text-[50px] md:text-[50px] sm:text-[28px]">Talk to Us</h1> */}

    <div className="hidden xl:block lg:block md:block flex w-screen pl-6 pt-10 pb-10 bg-gray-200 xl:px-96">
      <h1 className="w-10/12 text-left mx-auto text-left mb-10 xlg:text-[50px] xl:text-[50px] md:text-[30px] sm:text-[40px]">Talk to Us</h1>
      <form ref={form} onSubmit={sendEmail}>
        <div className="w-10/12 text-left mx-auto flex gap-8">
      <input
        name="fname"
        className="h-10 w-1/2 p-3"
        placeholder=" First Name"
      />
      <input
        name="lname"
        className="h-10 w-1/2 p-3"
        placeholder=" Last Name"
      />
        </div>


    <div className="w-10/12 text-left mx-auto ">
      <input
        name="from"
        type="email"
        className="w-full h-10 mt-5 p-3"
        placeholder=" Your Email"
      />
      </div>

    <div className="w-10/12 text-left mx-auto">
      <textarea
        name="message"
        placeholder=" Message"
        className="w-full mt-5  p-3"
        type="text"
        rows="5"
      />
    </div>
    <div className="w-full flex gap-8 mx-auto m-8 text-center">

<button type="submit" value="Send" style={{background:"#298AC5"}} className="mx-auto w-48 hover:bg-blue-500 text-white font-bold py-2 px-4 ">
  Send
</button>
    </div>
    </form>
    </div>

      <div className="text-white"> <ToastContainer
      position="top-right"
      autoClose={1000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      />
      </div>

      {/* for mobile */}

      <div className="sm:block xl:hidden lg:hidden md:hidden w-screen xl:pl-6 lg:pl-6 md:pl-6 sm:pl-0 pt-10 pb-10 bg-gray-200 xl:px-96">
      <h1 className="w-10/12 text-left mx-auto text-left mb-10 text-[28px]">Talk to Us</h1>
      <form ref={form} onSubmit={sendEmail}>
        <div className="w-10/12 text-left mx-auto flex gap-8">
      <input
        name="fname"
        className="h-[27px] w-1/2 p-3"
        placeholder=" First Name"
      />
      <input
        name="lname"
        className="h-[27px] w-1/2 p-3"
        placeholder=" Last Name"
      />
        </div>


    <div className="w-10/12 text-left mx-auto ">
      <input
        name="from"
        type="email"
        className="w-full h-[27px] mt-5 p-3"
        placeholder=" Your Email"
      />
      </div>

    <div className="w-10/12 text-left mx-auto">
      <textarea
        name="message"
        placeholder=" Message"
        className="w-full mt-5 h-[102px] p-3"
        type="text"
        rows="5"
      />
    </div>
    <div className="w-full flex gap-8 mx-auto m-8 text-center">

<button type="submit" value="Send" style={{background:"#298AC5"}} className="mx-auto w-44 hover:bg-blue-500 text-white font-bold py-2 px-4 ">
  Send
</button>
    </div>
    </form>
    </div>

      <div className="text-white"> <ToastContainer
      position="top-right"
      autoClose={1000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      />
      </div>
</>
  );
};

export default TalktoUs;
