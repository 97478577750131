import React, {useState} from "react";
import { images } from "../images/images";

function LogoCarousel() {
    const [firstImg, setFirstImg] = useState(0);
    const [lastImg, setLastImg] = useState(4);

    const images_arr = [
        images.gsf,
        images.brink,
        images.artesian,
        images.entrepreneur,
        images.powerhouse,
      ];

    const imgNext = (f_img,l_img) => {
        if(f_img !== 0){
          setFirstImg(f_img - 1);
          setLastImg(lastImg - 1);
      }
        
      }
    
      const imgPrev = (f_img,l_img) => {
      if(l_img < images_arr.length){
        setFirstImg(f_img + 1);
        setLastImg(lastImg + 1);
    }
    }

  return (
    <div className=" flex gap-8 justify-start items-center">
      {images_arr?.length > 4 &&
      <p onClick={()=>imgPrev(firstImg,lastImg)} className={` ml-2 h-10 cursor-pointer`}> {"<"} </p>
      }
      {images_arr.slice(firstImg, lastImg).map((img,index)=>(
      <div className="flex flex-col" key={index}>
      {/* <p className="whitespace-nowrap text-[14px]">{img.date} ( {img.visit} )</p> */}
      <img src={img} key={index} alt=""  />
      </div>
      ))}
      {images_arr?.length > 4 &&
      <p onClick={()=>imgNext(firstImg,lastImg)} className={` mr-2 h-10 cursor-pointer`}>{">"}</p>
      }
    </div>
  )
}

export default LogoCarousel