import { images } from "../images/images";
import video from "../video/hero.mp4";

const Renal = () => {
    return ( 
    
    <div className="overflow-hidden">
    {/* <div className="relative">

            <video loop autoplay='' muted className=" sm:h-[10rem] md:h-[53rem] object-fill w-[100%]" >
              <source src= { video } type="video/mp4" />
             
          </video>
          <span class="absolute bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-violet-500">
    Hello world
  </span>
          
    </div> */}
    

    <div class="block sm:block xl:hidden lg:hidden md:hidden relative">
    <video loop autoplay='' muted className=" " >
              <source src= { video } type="video/mp4" />
          </video>
    </div>

    <div class="hidden xl:block lg:block md:block relative">
    <video loop autoplay='' muted className=" " >
              <source src= { video } type="video/mp4" />
          </video>
        <div className="absolute sm:text-[18px] md:text-[32px] text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <p className="whitespace-pre">850 million people worldwide are affected by chronic kidney disease.</p>
        <p className="whitespace-pre">90% of them are not aware of it because it frequently goes undiagnosed until the very end.</p>
        <p className="whitespace-pre">This preventable death can be decreased by AI-powered Functional PoCUS.</p>
        </div>
    </div>

    <div className="m-4 block flex sm:block xl:hidden lg:hidden md:hidden">
            <p className="text-left p-2 text-[14px] text-justify">
            850 million people worldwide are affected by chronic kidney disease.
        90% of them are not aware of it because it frequently goes undiagnosed until the very end.
        This preventable death can be decreased by AI-powered Functional PoCUS.
                </p>
            </div>

            {/* <div className="hidden xl:block lg:block md:block flex xl:px-96 lg:px-4 mx-auto mt-10 text-gray-700">
                <p className="leading-10 pb-10 text-[22px] px-28 text-justify">
                Renal ultrasound should be available to any patient anywhere. 
                Our goal is to empower medical professionals
                to perform timely and accurate renal ultrasound regardless
                of their sonography training.
                </p>
            </div>
            <div className="m-4 block flex sm:block xl:hidden lg:hidden md:hidden">
            <p className="text-left p-2 text-[14px] text-justify">
            Renal ultrasound should be available to any patient anywhere. 
            Our goal is to empower medical professionals
            to perform timely and accurate renal ultrasound regardless
            of their sonography training.
                </p>
            </div> */}
        {/* <div className="flex mb-5 text-left">
      <p className="mb-5 mx-auto text-[22px]">
        Our, patent pending software pairs with handheld ultrasound device to
        conduct renel sonography<br/> at the point of care ,helping more patients be
        accurately diagnosed and treated with chronic<br/> kidney disease.
      </p>
      </div> */}
    </div> 
    );
}
 
export default Renal;