import "./App.css";
import AboutUs from "./components/AboutUs";
import TalktoUs from "./components/TalktoUs";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Renal from "./components/Renal";
import OurSolution from "./components/OurSolution";

function App() {
  return (
    <div className="App overflow-hidden">
      <Header />
      <Renal />
      <OurSolution />
      <AboutUs />
      <TalktoUs />
      <Footer />
    </div>
  );
}

export default App;
