import {Link} from 'react-scroll'
import AboutUs from './AboutUs';
import React from 'react'
import { MdLocationPin, MdPhoneAndroid } from "react-icons/md";
import { HiMail } from "react-icons/hi";
import { FaFacebookSquare, FaLinkedinIn, FaTwitter } from "react-icons/fa";

function Footer() {
  return (
    <>
    <div className="w-screen hidden xl:block lg:block md:block">
      <footer className="bg-black  py-5 md:px-28 text-gray-400">
      <div className="mx-auto lg:px-36 xl:px-0 xl:w-1/2">

        <div className="flex  mx-auto justify-between mt-4">
        <h4 className="text-[19px] cursor-pointer"><Link  to="solution" spy={true} smooth={true}>Our Solution</Link></h4>
        <div className="flex items-center">
          <p>info@healium.ai</p>
          <a href="#">
        <svg
                className=" ml-3 w-3/4"
                xmlns="http://www.w3.org/2000/svg"
                width="28.444"
                height="19.2"
                viewBox="0 0 28.444 19.2"
              >
                <path
                  id="Icon_zocial-email"
                  data-name="Icon zocial-email"
                  d="M.072,21.568V5.769q0-.027.082-.521l9.3,7.954L.182,22.116a2.325,2.325,0,0,1-.11-.549ZM1.306,4.15a1.182,1.182,0,0,1,.466-.082H26.815a1.553,1.553,0,0,1,.494.082l-9.326,7.982-1.234.987-2.441,2-2.441-2-1.234-.987Zm.027,19.036,9.353-8.969,3.621,2.935,3.621-2.935,9.353,8.969a1.317,1.317,0,0,1-.466.082H1.773a1.242,1.242,0,0,1-.439-.082ZM19.163,13.2l9.271-7.954a1.637,1.637,0,0,1,.082.521v15.8a2.1,2.1,0,0,1-.082.549Z"
                  transform="translate(-0.072 -4.068)"
                  fill="#fff"
                />
              </svg>
              </a>
        </div>
        </div>

        <div className="flex justify-between mt-4">
        <h4 className="text-[19px] cursor-pointer"><Link  to="about" spy={true} smooth={true}>About Us</Link></h4>
        <div className="flex items-center">
          <p>+65 31351024</p>
          <a href="#">
          <svg
                className=" ml-4 w-6"
                xmlns="http://www.w3.org/2000/svg"
                width="19.091"
                height="30"
                viewBox="0 0 19.091 30"
              >
                <path
                  id="Icon_material-phone-android"
                  data-name="Icon material-phone-android"
                  d="M22.5,1.5H11.591A4.085,4.085,0,0,0,7.5,5.591V27.409A4.085,4.085,0,0,0,11.591,31.5H22.5a4.085,4.085,0,0,0,4.091-4.091V5.591A4.085,4.085,0,0,0,22.5,1.5ZM19.773,28.773H14.318V27.409h5.455ZM24.2,24.682H9.886V5.591H24.2Z"
                  transform="translate(-7.5 -1.5)"
                  fill="#fff"
                />
              </svg>
              </a>
        </div>
        </div>

        <div className="flex justify-between mt-4">
        <h4 className="text-[19px] cursor-pointer"><Link  to="investors" spy={true} smooth={true}>Our Investors</Link></h4>
        <div className="flex items-center text-right">
          <p>6 Raffles Quay #11-07,<br/> Singapore 048580</p>
          <a href="#">
          <svg
                className="w-5 ml-5 "
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="30"
                viewBox="0 0 21 30"
              >
                <path
                  id="Icon_material-location-on"
                  data-name="Icon material-location-on"
                  d="M18,3A10.492,10.492,0,0,0,7.5,13.5C7.5,21.375,18,33,18,33S28.5,21.375,28.5,13.5A10.492,10.492,0,0,0,18,3Zm0,14.25a3.75,3.75,0,1,1,3.75-3.75A3.751,3.751,0,0,1,18,17.25Z"
                  transform="translate(-7.5 -3)"
                  fill="#fff"
                />
              </svg>
              </a>
        </div>
        </div>

        <div className="lg:flex xl:flex justify-between mt-10">
        <h4 className="text-[19px] md:ml-[-60px] lg:ml-0 xl:ml-0">@ Healium Digital Healthcare PTE. LTD 2022. All Rights Reserved</h4>
        <div className="flex items-center">
          <div className="flex items-center gap-8 md:mt-5 xl:mt-0 lg:mt-0">
                <div>
                  <a href="https://www.linkedin.com/company/healiumdigitalhealth/ ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 31.5 31.5"
                  >
                    <path
                      id="Icon_awesome-linkedin-in"
                      data-name="Icon awesome-linkedin-in"
                      d="M7.051,31.5H.52V10.47H7.051ZM3.782,7.6A3.8,3.8,0,1,1,7.564,3.783,3.814,3.814,0,0,1,3.782,7.6ZM31.493,31.5H24.976V21.263c0-2.44-.049-5.569-3.4-5.569-3.4,0-3.916,2.651-3.916,5.393V31.5H11.142V10.47h6.263v2.869H17.5a6.862,6.862,0,0,1,6.179-3.4c6.609,0,7.824,4.352,7.824,10.005V31.5Z"
                      transform="translate(0 0)"
                      fill="#fff"
                    />
                  </svg>
                  </a>
                </div>
                <div>
                  <a href="https://twitter.com/DigitalHealium">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 36 29.238"
                  >
                    <path
                      id="Icon_awesome-twitter"
                      data-name="Icon awesome-twitter"
                      d="M32.3,10.668c.023.32.023.64.023.959,0,9.754-7.424,20.992-20.992,20.992A20.85,20.85,0,0,1,0,29.307a15.263,15.263,0,0,0,1.782.091,14.776,14.776,0,0,0,9.16-3.152,7.391,7.391,0,0,1-6.9-5.117,9.3,9.3,0,0,0,1.393.114,7.8,7.8,0,0,0,1.942-.251,7.379,7.379,0,0,1-5.916-7.241V13.66A7.431,7.431,0,0,0,4.8,14.6,7.389,7.389,0,0,1,2.513,4.728a20.972,20.972,0,0,0,15.213,7.721,8.329,8.329,0,0,1-.183-1.69A7.385,7.385,0,0,1,30.312,5.711a14.526,14.526,0,0,0,4.683-1.782,7.358,7.358,0,0,1-3.244,4.066A14.791,14.791,0,0,0,36,6.853a15.86,15.86,0,0,1-3.7,3.815Z"
                      transform="translate(0 -3.381)"
                      fill="#fff"
                    />
                  </svg>
                  </a>
                </div>
                <div>
                  <a href="https://m.facebook.com/HealiumHeathcare?_rdr">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 29.239 29.239"
                  >
                    <path
                      id="Icon_ionic-logo-facebook"
                      data-name="Icon ionic-logo-facebook"
                      d="M32.124,4.5H6.114A1.615,1.615,0,0,0,4.5,6.114v26.01a1.615,1.615,0,0,0,1.614,1.614H19.119V22.165H15.624V17.9h3.495V14.749c0-3.777,2.619-5.832,5.992-5.832,1.614,0,3.35.122,3.754.175v3.944H26.178c-1.835,0-2.185.868-2.185,2.147V17.9h4.371l-.571,4.264h-3.8V33.739h8.132a1.615,1.615,0,0,0,1.614-1.614V6.114A1.615,1.615,0,0,0,32.124,4.5Z"
                      transform="translate(-4.5 -4.5)"
                      fill="#fff"
                    />
                  </svg>
                  </a>
                </div>
              </div>
        </div>
        </div>
        </div>
      </footer>
    </div>

    {/* for mobile */}

    {/* <div className="w-screen bg-black sm:block xl:hidden lg:hidden md:hidden">
      <footer className="bg-black pt-[40px] pb-[150px] text-gray-400">
      <div className="flex-col mx-auto lg:px-36 xl:px-0 xl:w-1/2">

        <div className="flex gap-28 justify-center mt-4 mx-auto">
        <h4 className="text-[12px]"><Link  to="solution" spy={true} smooth={true}>Our Solution</Link></h4>
        <div className="flex items-center">
          <p className='text-[10px]'>info@healium.ai</p>
          <a href="#">
        <svg
                className=" ml-4 w-[20px]"
                xmlns="http://www.w3.org/2000/svg"
                width="28.444"
                height="19.2"
                viewBox="0 0 28.444 19.2"
              >
                <path
                  id="Icon_zocial-email"
                  data-name="Icon zocial-email"
                  d="M.072,21.568V5.769q0-.027.082-.521l9.3,7.954L.182,22.116a2.325,2.325,0,0,1-.11-.549ZM1.306,4.15a1.182,1.182,0,0,1,.466-.082H26.815a1.553,1.553,0,0,1,.494.082l-9.326,7.982-1.234.987-2.441,2-2.441-2-1.234-.987Zm.027,19.036,9.353-8.969,3.621,2.935,3.621-2.935,9.353,8.969a1.317,1.317,0,0,1-.466.082H1.773a1.242,1.242,0,0,1-.439-.082ZM19.163,13.2l9.271-7.954a1.637,1.637,0,0,1,.082.521v15.8a2.1,2.1,0,0,1-.082.549Z"
                  transform="translate(-0.072 -4.068)"
                  fill="#fff"
                />
              </svg>
              </a>
        </div>
        </div>

        <div className="flex gap-36 items-center ml-3 mt-4 mx-auto">
        <h4 className="text-[12px] text-left ml-[26px] cursor-pointer"><Link  to="about" spy={true} smooth={true}>About Us</Link></h4>
        <div className="flex items-center">
          <p className='text-[10px] '>+65 31351024</p>
          <a href="#">
          <svg
                className=" ml-3 w-[14px]"
                xmlns="http://www.w3.org/2000/svg"
                width="19.091"
                height="30"
                viewBox="0 0 19.091 30"
              >
                <path
                  id="Icon_material-phone-android"
                  data-name="Icon material-phone-android"
                  d="M22.5,1.5H11.591A4.085,4.085,0,0,0,7.5,5.591V27.409A4.085,4.085,0,0,0,11.591,31.5H22.5a4.085,4.085,0,0,0,4.091-4.091V5.591A4.085,4.085,0,0,0,22.5,1.5ZM19.773,28.773H14.318V27.409h5.455ZM24.2,24.682H9.886V5.591H24.2Z"
                  transform="translate(-7.5 -1.5)"
                  fill="#fff"
                />
              </svg>
              </a>
        </div>
        </div>

        <div className="flex gap-20 justify-center mt-4 mx-auto">
        <h4 className="text-[12px] "><Link  to="investors" spy={true} smooth={true}>Our Investors</Link></h4>
        <div className="flex items-center text-right">
          <p className='text-[10px]'>6 Raffles Quay #11-07,<br/> Singapore 048580</p>
          <a href="#">
          <svg
                className="w-[14px] ml-4"
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="30"
                viewBox="0 0 21 30"
              >
                <path
                  id="Icon_material-location-on"
                  data-name="Icon material-location-on"
                  d="M18,3A10.492,10.492,0,0,0,7.5,13.5C7.5,21.375,18,33,18,33S28.5,21.375,28.5,13.5A10.492,10.492,0,0,0,18,3Zm0,14.25a3.75,3.75,0,1,1,3.75-3.75A3.751,3.751,0,0,1,18,17.25Z"
                  transform="translate(-7.5 -3)"
                  fill="#fff"
                />
              </svg>
              </a>
        </div>
        </div>

        <div className="float-right mr-5 mt-[10px] mb-8">
          <div className="flex float-right gap-2 m-4 mr-5">
                <div className='items-center'>
                  <a href="https://www.linkedin.com/company/healiumdigitalhealth/ ">
                  <svg
                   className="w-[18px]"
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 31.5 31.5"
                  >
                    <path
                      id="Icon_awesome-linkedin-in"
                      data-name="Icon awesome-linkedin-in"
                      d="M7.051,31.5H.52V10.47H7.051ZM3.782,7.6A3.8,3.8,0,1,1,7.564,3.783,3.814,3.814,0,0,1,3.782,7.6ZM31.493,31.5H24.976V21.263c0-2.44-.049-5.569-3.4-5.569-3.4,0-3.916,2.651-3.916,5.393V31.5H11.142V10.47h6.263v2.869H17.5a6.862,6.862,0,0,1,6.179-3.4c6.609,0,7.824,4.352,7.824,10.005V31.5Z"
                      transform="translate(0 0)"
                      fill="#fff"
                    />
                  </svg>
                  </a>
                </div>
                <div>
                  <a href="https://twitter.com/DigitalHealium">
                  <svg
                   className="w-[20px]"
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 36 29.238"
                  >
                    <path
                      id="Icon_awesome-twitter"
                      data-name="Icon awesome-twitter"
                      d="M32.3,10.668c.023.32.023.64.023.959,0,9.754-7.424,20.992-20.992,20.992A20.85,20.85,0,0,1,0,29.307a15.263,15.263,0,0,0,1.782.091,14.776,14.776,0,0,0,9.16-3.152,7.391,7.391,0,0,1-6.9-5.117,9.3,9.3,0,0,0,1.393.114,7.8,7.8,0,0,0,1.942-.251,7.379,7.379,0,0,1-5.916-7.241V13.66A7.431,7.431,0,0,0,4.8,14.6,7.389,7.389,0,0,1,2.513,4.728a20.972,20.972,0,0,0,15.213,7.721,8.329,8.329,0,0,1-.183-1.69A7.385,7.385,0,0,1,30.312,5.711a14.526,14.526,0,0,0,4.683-1.782,7.358,7.358,0,0,1-3.244,4.066A14.791,14.791,0,0,0,36,6.853a15.86,15.86,0,0,1-3.7,3.815Z"
                      transform="translate(0 -3.381)"
                      fill="#fff"
                    />
                  </svg>
                  </a>
                </div>
                <div>
                  <a href="https://m.facebook.com/HealiumHeathcare?_rdr">
                  <svg
                   className="w-[18px]"
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 29.239 29.239"
                  >
                    <path
                      id="Icon_ionic-logo-facebook"
                      data-name="Icon ionic-logo-facebook"
                      d="M32.124,4.5H6.114A1.615,1.615,0,0,0,4.5,6.114v26.01a1.615,1.615,0,0,0,1.614,1.614H19.119V22.165H15.624V17.9h3.495V14.749c0-3.777,2.619-5.832,5.992-5.832,1.614,0,3.35.122,3.754.175v3.944H26.178c-1.835,0-2.185.868-2.185,2.147V17.9h4.371l-.571,4.264h-3.8V33.739h8.132a1.615,1.615,0,0,0,1.614-1.614V6.114A1.615,1.615,0,0,0,32.124,4.5Z"
                      transform="translate(-4.5 -4.5)"
                      fill="#fff"
                    />
                  </svg>
                  </a>
                </div>
              </div>
              <div className="flex-col mt-4 float-right">
          <h4 className="text-[8px]">@ Healium Digital Healthcare PTE. LTD 2022. All Rights Reserved</h4>
        </div>

        </div>
       
        </div>
      </footer>
    </div> */}

      <footer className="p-4 text-gray-400 sm:p-6 bg-black sm:block xl:hidden lg:hidden md:hidden">
        <div className="md:flex md:justify-between">
          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
            <div>
              <ul className="text-gray-400 dark:text-gray-400">
                <li className="mb-5 mt-2">
                  <a
                    href="#"
                    className="flex text-left text-[12px]"
                  >
                    Our Solution
                  </a>
                </li>
                <li className="mb-5 mt-3">
                  <a
                    href="#"
                    className="flex text-left text-[12px]"
                  >
                    About Us
                  </a>
                </li>
                <li className='mt-2'>
                  <a
                    href="#"
                    className="flex text-left text-[12px]"
                  >
                    Our Investors
                  </a>
                </li>
              </ul>
            </div>

          {/**/}
            <div>
              <ul className="text-gray-600 dark:text-gray-400">
                <li className="mb-4 flex text-left justify-center items-center">
                  <a href="#" className="text-[12px]">
                    info@healium.ai
                  </a>
                  <HiMail className='h-7 text-white w-6 ml-2'/>
                </li>
                <li className='mb-4 flex text-left justify-center items-center'>
                  <a href="#" className="text-[12px]" >
                    +65 31351024
                  </a>
                  <MdPhoneAndroid className='h-7 text-white w-6 ml-4' />
                </li>

                <li className='flex items-center mb-4 text-left'>
                  <p className="flex ml-0 text-[12px]">
                    6 Raffles Quay #11-07,
                    Singapore 048580
                  </p>
                  <MdLocationPin className='h-7 text-white w-1/2 mr-2'/>
                  
                </li>

              </ul>
            </div>
          </div>
        </div>

        <h4 className="text-[11px] text-left text-gray-400 mt-4">@ Healium Digital Healthcare PTE. LTD 2022. All Rights Reserved</h4>
       
        <div class="">
        <div class="flex mt-4 space-x-6 sm:justify-center sm:mt-0">
            <a href="https://www.linkedin.com/company/healiumdigitalhealth/" class="text-white hover:text-gray-900 dark:hover:text-white">
              <FaLinkedinIn />
            </a>

            <a href="https://twitter.com/DigitalHealium" class="text-white hover:text-gray-900 dark:hover:text-white">
              <FaTwitter />
            </a>

            <a href="https://m.facebook.com/HealiumHeathcare?_rdr" class="text-white hover:text-gray-900 dark:hover:text-white">
              <FaFacebookSquare />
            </a>
           
        </div>
    </div>
      </footer>

    </>
  )
}

export default Footer
