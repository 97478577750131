import brink from "./•brinc_Gradient_RGB+(1).png";
import artesian from "./4a772d_9cc8c6f1e4444616887c7a94dfb0df6c_mv2.png";
import aboutUsImage from "./Case-Study-Thermo-v5.png";
import entrepreneur from "./entrepreneur-first-logo-vector.png";
import entrepreneur2 from "./entrepreneur-first-logo-vector2.png";
import gsf from "./gsf.png";
import powerhouse from "./powerhouse.png";
import powerhouse_new from "./powerhouse_new.png";
import healium from "./logo_healium.png";
import renal from "./header1.png";
import search from "./search.png";
import hand from "./hand.png";
import operation from "./operations.png";
export const images = {
  search: search,
  hand: hand,
  operation: operation,
  renal: renal,
  healium: healium,
  brink: brink,
  artesian: artesian,
  aboutUsImage: aboutUsImage,
  entrepreneur: entrepreneur,
  entrepreneur2: entrepreneur2,
  gsf: gsf,
  powerhouse: powerhouse,
  powerhouse_new: powerhouse_new,

};
