import { images } from "../images/images";
import React from 'react'

function OurSolution() {
  return (
    <>
    <div
      className="hidden xl:block lg:block md:block bg-our_sol flex-col"
      style={{ background: "#F0F3F5" }}
    >
      <div className="flex-1 xl:px-96 lg:px-4">
        <h1 id="solution" className="mx-auto py-10 lg:text-[50px] xl:text-[50px] md:text-[30px] text-black text-left w-10/12 md:ml-28 xl:ml-[100px]">
          Our Solution
        </h1>
      </div>

<div className="flex-col lg:px-28 md:px-28 xl:px-[490px] mb-5">
      <div className=" mb-2 text-left text-justify">
        <p className="mb-5 mx-auto text-[22px]">

        Our, AI assisted handheld 3D ultrasound device to conduct renal sonography at the point of
        care, helping more patients be accurately diagnosed and treated with chronic kidney disease.
        </p>
      </div>

      <div className=" mb-5 text-left text-justify">
        <p className="mb-5 text-[22px] mx-auto">
        Point of care renal 3D ultrasound device allows medical professionals to screen and 
         diagnose problems wherever a patient is treated, from doctor's office, 
        community hospitals, ambulances,  to remote communities.
        </p>
      </div>

      <div className=" mb-5 text-left text-justify">
        <p className="mb-5 mx-auto text-[22px]">
        Our technology addresses user-skill barriers, early detection of the
        chronic kidney disease by providing 
        clinicians, regardless of their level of sonography 
        training, real-time on-screen guidance,  and quality assessment.
        </p>
      </div>

      </div>
      <div className="xl:flex lg:flex md:flex justify-center xl:gap-10 lg:gap-10 pb-16 mt-5">
        <div className="mt-10 md:ml-5">
          <img src={images.hand} alt="" className="w-32" />
          <div className="mt-5 text-left">
            <h4 className="text-[22px] text-black">Early Detection</h4>
            <p className="mt-2 text-black">
              Detect CKD from
              <br />
              stage-1
            </p>
          </div>
        </div>
        <div className="mt-10 xl:pl-10 lg:pl-10 md:ml-5">
          <img src={images.search} alt="" className="w-32" />
          <div className="mt-5 text-left">
            <h4 className="text-[22px] text-black">Availability</h4>
            <p className="mt-2 text-black">
              Make diagnostic information
              <br /> available on fingertip
            </p>
          </div>
        </div>
        <div className="mt-10 md:ml-5">
          <img src={images.operation} alt="" className="w-32" />
          <div className="mt-5 text-left">
            <h4 className="text-[22px] text-black">Operational Efficiency</h4>
            <p className="mt-2 text-black">
              Reduce sonographer
              <br /> training challenge
            </p>
          </div>
        </div>
      </div>
    
    </div>

    {/* for mobile  */}
    <div
      className="sm:block xl:hidden lg:hidden md:hidden p-2 bg-our_sol flex-col"
      style={{ background: "#F0F3F5" }}
    >
      <h1 className="mx-auto py-5 ml-5 text-[28px] text-black text-left">
        Our Solution
      </h1>

      <div className="flex mb-2 px-5 text-center text-justify">
        <p className="mb-2 mx-auto text-[14px]">
        Our, AI assisted handheld 3D ultrasound device to conduct renal sonography at the point
of care, helping more patients be accurately diagnosed and treated with chronic kidney disease.
        </p>
      </div>

      <div className="flex mb-2 px-5 text-center text-justify">
        <p className="mb-2 text-[14px] mx-auto">
        Point of care renal 3D ultrasound device allows medical professionals to screen and diagnose
problems wherever a patient is treated, from doctor's office, community hospitals, ambulances,
to remote communities.
        </p>
      </div>

      <div className="flex mb-2 px-5 text-center text-justify">
        <p className="mb-2 mx-auto text-[14px]">
        Our technology addresses user-skill barriers, early detection of the chronic kidney disease by
providing clinicians, regardless of their level of sonography training, real-time on-screen guidance, and quality assessment.
        </p>
      </div>
      <div className="flex-col justify-center gap-40 p-2">
        <div className="flex gap-5 mt-10 items-center justify-center ml-[-40px]">
          <img src={images.hand} alt="" className="w-[120px] h-[120px]" />
          <div className="text-left">
            <h4 className="text-[22px] text-black"><b>Early Detection</b></h4>
            <p className="mt-2 text-[21px] text-black">
              Detect CKD from
              <br />
              stage-1
            </p>
          </div>
        </div>
        <div className="flex gap-5 mt-10 items-center justify-center">
          <img src={images.search} alt="" className="w-[120px] h-[120px]" />
          <div className=" text-left">
            <h4 className="text-[22px] text-black"><b>Availability</b></h4>
            <p className="mt-2 text-[21px] text-black">
              Make diagnostic information
              <br /> available on fingertip
            </p>
          </div>
        </div>
        <div className="flex gap-5 mt-10 items-center justify-center">
          <img src={images.operation} alt="" className="w-[120px] h-[120px]" />
          <div className="text-left">
            <h4 className="text-[22px] text-black"><b>Operational Efficiency</b></h4>
            <p className="mt-2 text-[21px] text-black">
              Reduce sonographer
              <br /> training challenge
            </p>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}

export default OurSolution;
